'use strict';
require('./spread-fixed.scss'); 
const ga = require('../common/lib/utils.js')._ga;
const slideshow = require('../common/lib/slider.js').slideshow;
slideshow('slider-block','wrap','slider-index','wrap wrap-list',5);
const $fixedDownloadBtn = $('#fixedDownloadBtn');
$fixedDownloadBtn.on('click', function() {
  // console.log('click_fixed_download');
  ga('click_fixed_download');
});
