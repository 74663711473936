'use strict';
exports.slideshow=function (slideshowDom,descripsDom,pagesDom,descripsClass,times) {
    descripsClass=descripsClass || descripsDom
    console.log(slideshowDom)
    let slideshow = document.getElementById(slideshowDom)
    if(!slideshow) return
    let descrips = slideshow.getElementsByClassName(descripsDom), // 内容
        pages = slideshow.getElementsByClassName(pagesDom), // 进度
        length = descrips.length, // 内容数目
        current = 1; // current为当前内容的编号
    function changeSlide() { // 切换内容函数
        for (let i = 0; i < length; i++) {
            if (i == current) {
                pages[i].className = pagesDom+' active';
                descrips[i].className = descripsClass+' active';
            } else {
                pages[i].className = pagesDom;
                if (i < current) {
                    descrips[i].className = descripsClass+' left';
                } else {
                    descrips[i].className = descripsClass;
                }
            }
        } 
        current++; // 自增1 
        if (current >= length) {
            current = 0;
        }
    }
    const silder_time = times || 5;
    // 每4s调用changeSlide函数进行内容轮播
    let slideon = setInterval(changeSlide, silder_time * 1000);

    slideshow.onmouseover = function() {
        clearInterval(slideon); // 当鼠标移入时清除轮播事件
    };
    slideshow.onmouseout = function() {
        slideon = setInterval(changeSlide, silder_time * 1000); // 当鼠标移出时重新开始轮播事件
    };

    for (let i = 0; i < length; i++) { // 定义鼠标移入页码事件
        pages[i].onmouseover = function() {
        current = this.getAttribute('name'); // 得到当前鼠标指的页码
            changeSlide();
        };
    } 
}